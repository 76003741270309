<template>
  <div>
    <div class="card" :style="{ height: height }">
      <div class="flexBox">
        <div class="btns" style="flex-shrink: 0;">
          <el-button type="primary" @click="handleClick" icon="el-icon-plus">添加</el-button>
          <el-button type="primary" plain icon="el-icon-upload2" @click="handleExport" :loading="exportLoading">导出</el-button>
        </div>
        <div class="form">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" label-width="90px">
            <el-form-item label="手机号码">
              <el-input v-model="formInline.username" placeholder="请输入手机号码" clearable @keydown.enter.native="onSubmit"></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="formInline.realname" placeholder="请输入姓名" clearable  @keydown.enter.native="onSubmit"></el-input>
            </el-form-item>
            <el-form-item label="门店">
              <el-select v-model="formInline.hierarchy_ids"  multiple collapse-tags clearable filterable>
                <template v-for="item in merchantList">
                  <el-option :key="item.hierarchy_id" :label="item.hierarchy_name" :value="item.hierarchy_id"></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="性别">
              <el-select v-model="formInline.gender" filterable clearable>
                <el-option label="男" value="10"></el-option>
                <el-option label="女" value="20"></el-option>
              </el-select>
            </el-form-item>
             <el-form-item label="状态">
              <el-select v-model="formInline.status" filterable clearable>
                <el-option label="在职" value="10"></el-option>
                <el-option label="离职" value="20"></el-option>
              </el-select>
            </el-form-item>
             <el-form-item label="职位">
              <el-select v-model="formInline.group_ids"  multiple collapse-tags clearable filterable>
                <template v-for="item in roleList">
                  <el-option :key="item.id" :label="item.title" :value="item.id"></el-option>
                </template>
              </el-select>
            </el-form-item>
            <!-- <el-form-item>
              <el-button type="primary" @click="onSubmit">搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-button  @click="resetForm">重置</el-button>
            </el-form-item> -->
          </el-form>
        </div>
        <div style="margin: 0 10px 0 auto">
          <el-button
            @click="handleHeight"
            style="
              width: 40px;
              height: 40px;
              line-height: 36px;
              text-align: center;
              padding: 0;
            "
          >
            <i class="el-icon-caret-bottom" v-if="height === '51px'"></i>
            <i class="el-icon-caret-top" v-else></i>
          </el-button>
        </div>
          <div style="display: flex">
            <el-button type="primary"  @click="onSubmit" style="height: 40px"
              >搜索</el-button
            >
            <el-button  @click="resetForm" style="height: 40px;">重置</el-button>
        </div>
      </div>
     </div>
    <div class="table">
      <el-table
        v-loading="loading"
        :data="listData.list"
        :row-class-name="handleRow"
        height="700"
        stripe
        style="width: 100%">
        <el-table-column
          align="center"
          fixed="right"
          width="180"
          label="操作" :AuthpermissionsData="AuthpermissionsData">
          <template slot-scope="scope">
            <el-dropdown trigger="click" @command="handleSelect">
                  <span class="el-dropdown-link">
                    请选择<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item  :command="beforeHandleCommand(scope.row,'0')"  >编辑</el-dropdown-item>
                    <el-dropdown-item  :command="beforeHandleCommand(scope.row,'2')" v-if="scope.row.bind_type == 1">绑定企微</el-dropdown-item>
                    <el-dropdown-item  :command="beforeHandleCommand(scope.row,'3')" v-if="scope.row.bind_type == 3">解绑企微</el-dropdown-item>
                    <el-dropdown-item  :command="beforeHandleCommand(scope.row,'4')"   v-if='AuthpermissionsData["store/authority.user/deleteuser"]'  @click.native="removeUser(scope.row)">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column
          prop="index"
          align="center"
          label="序号">
        </el-table-column>
        <el-table-column
          prop="realname"
          align="center"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="hierarchy_name"
          align="center"
          label="门店">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.hierarchy_name" :key="index" style="line-height: 40px;">
              {{item}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="role_name"
          align="center"
          label="职位">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.role_name" :key="index" style="line-height: 40px;">
              {{item}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="username"
          align="center"
          label="手机">
        </el-table-column>
        <el-table-column
          prop="gender"
          align="center"
          label="性别">
            <template slot-scope="scope">
              {{ scope.row.gender == '10'? '男': scope.row.gender == '20'? '女': '未知' }}
            </template>
        </el-table-column>
        <el-table-column
          prop="status"
          align="center"
          filter-placement="bottom-end"
          label="状态">
          <template slot-scope="scope">
              {{ scope.row.status == '10'? '在职': scope.row.status == '20'? '离职': '未知' }}
            </template>
        </el-table-column>
        <el-table-column
          prop="bind_text"
          align="center"
          filter-placement="bottom-end"
          label="企业微信">
        </el-table-column>
        <el-table-column
          prop="create_user_name"
          align="center"
          label="添加人">
        </el-table-column>
        <el-table-column
          prop="update_time"
          align="center"
          label="更新时间">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagina">
        <el-pagination background @size-change="handleSizeChange"
          :current-page="formInline.page"
         :page-sizes="$store.state.pageSizes"
          layout='total, sizes, prev, pager, next, jumper' :total="listData.total || 0" @current-change="changePage" :page-size="$store.state.pageSizes[0]"></el-pagination>
      </div>
    <el-dialog
      :title="edit? '编辑员工': '添加员工'"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
     >
      <el-form :model="form">
        <el-form-item label="员工姓名" :label-width="formLabelWidth" required>
          <el-input v-model="form.realname" autocomplete="off" placeholder="请输入用户名称" style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="手机号" :label-width="formLabelWidth" required>
          <el-input v-model="form.username" autocomplete="off" placeholder="请输入手机号" :disabled="edit"  style="width: 50%"></el-input>
        </el-form-item>
        <el-form-item label="性别" :label-width="formLabelWidth" required>
          <el-select v-model="form.gender" placeholder="请选择性别">
            <el-option label="男" value="10"></el-option>
            <el-option label="女" value="20"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在门店" :label-width="formLabelWidth" required>
          <el-select v-model="form.hierarchy_ids" placeholder="请选择门店" multiple collapse-tags @change="handleChoose">
            <template v-for="item in merchantList">
              <el-option :label="item.hierarchy_name" :value="item.hierarchy_id" :key="item.hierarchy_id"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <template v-for="item in currentMerchantList">
          <el-form-item :label="item.hierarchy_name + '所属职位'" :label-width="formLabelWidth" required :key="item.hierarchy_id">
            <el-select v-model="role[item.hierarchy_id]" :placeholder="'选择'+item.hierarchy_name+'职位'" >
              <template v-for="item in roleListObj[item.hierarchy_id]">
                <el-option :label="item.title" :value="item.id" :key="item.id"></el-option>
              </template>
            </el-select>
          </el-form-item>
        </template>
        <el-form-item label="入职时间" :label-width="formLabelWidth" required>
          <el-date-picker
            v-model="form.hiredate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择入职时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth" required>
          <el-select v-model="form.status" placeholder="请选择状态">
            <el-option label="在职" value="10"></el-option>
            <el-option label="离职" value="20"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleTrue" :loading="loading2">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="deleVisible" width="30%" :before-close="handleClose">
      <span>确定要删除该用户吗?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleDele">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="codeVisible" title="绑定员工企业微信" width="40%" :before-close="handleCloseCode">
        <div ref="qrCodeUrl" class="qrcode" id="qrcode"></div>
        <div class="Code_text">请用企业微信-E店宝应用扫一扫进行授权</div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleCloseCode">取 消</el-button>
          <el-button type="primary" @click="handleCode">确 定</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import QRCode from 'qrcodejs2';
import {getMyAuth} from "@/api/authority/config";
import { ListRequest, create, edit, userDelete, hierListReq, roleListReq, getMerchantListReq,bindEwechatUrl,unbindEwechat,bindPersonEwechatUrl,removeUser} from '@/api/authority/menage';
let  timename;
let store_user_id;
export default {
  name: 'Manage',
  data() {
    return {
      showCoupon: true,
      isInfo:true,
      offsetHeight: "124px",
      height: "51px",
      exportLoading: false,
      roleListObj: {}, // 当前门店有什么职位可以选择的集合
      currentMerchantList: [], // 当前选择的门店列表
      role: {}, // 当前门店选择的职位是什么
      filterMerchantList: [],
      merchantList: [],
      filterRolesArr: [],
      roleList: [],
      agentList: [],
      edit: false,
      loading2: false,
      current: {},
      deleVisible: false,
      codeVisible: false,//二维码的显示隐藏
      loading: false,
      dialogVisible: false,
      form: {
        realname: '',
        username: '',
        gender: '',
        hiredate: '',
        status: '10',
        hierarchy_ids: [],
      },
      formLabelWidth: '200px',
      formInline: {
        realname: '',
        username: '',
        group_ids: [],
        gender: [],
        status: [],
        hierarchy_ids: [],
        limit: this.$store.state.pageSizes[0],
        page: 1,
      },
      listData: [],
      AuthpermissionsData:{},//系统权限数据
    };
  },

  activated() {
    // console.log('执行activated');
    // console.log(this.$vnode.parent.componentInstance.cache);
    this.getRoleList();
  },
  deactivated() {
    clearInterval(timename);
  },
  methods: {

    removeUser(item){

       //初始化表格配置
        this.$confirm('确定删除员工?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            removeUser({
              id:item.account_id
            }).then(res=>{
              if(res.code==1){
                console.log(res)
                this.$message({
                  type:'success',
                  message:'删除成功',
                })
                this.getList();
              }
            })
        }).catch({});
      
    },

    handleHeight() {
      // if (parseInt(this.offsetHeight) <= 62) {
      //   this.$message.warning("没有更多条件");
      //   return;
      // }
      this.height = this.height === "51px" ? this.offsetHeight : "51px";
    },
    resetForm() {
      this.formInline = {
        page: 1,
        limit: this.$store.state.pageSizes[0],
      };
      this.getList();
    },
    //操作下拉菜单
    beforeHandleCommand(row,command){
      return {
            'row': row,
            'command':command
        }
    },
     handleSelect(command) {
      let e=command.command;
      let row=command.row;
      if (e == 0) {
        this.edit = true;
        this.dialogVisible = true;
        this.current = row;
        this.form = { ...row };
        // 处理回填数据
        const merchantList = [...this.merchantList];
        const currentMerchantList = [];
        this.form.hierarchy_ids.forEach((item) => {
          merchantList.forEach((mItem) => {
            if (mItem.hierarchy_id === item) {
              currentMerchantList.push(mItem);
            }
          });
        });
        const role = {};
        this.form.hierarchy_group.forEach((item) => {
          role[item.hierarchy_id] = item.group_id;
        });
        this.role = role;
        this.currentMerchantList = currentMerchantList;
        const roleList = [...this.roleList];
        const roleListObj = { ...this.roleListObj };
        // 处理不同门店有不同职位
        const arr = [];
        currentMerchantList.forEach((item) => {
          arr.push(item.hierarchy_id);
        });
        arr.forEach((item) => {
          roleList.forEach((rItem) => {
            if (rItem.hierarchy_id === item || rItem.hierarchy_id === '1') {
              if (!roleListObj[item]) {
                roleListObj[item] = [];
              }
              let m = 0;
              if (!roleListObj[item].length) {
                roleListObj[item].push(rItem);
              } else {
                roleListObj[item].forEach((objItem) => {
                  if (objItem.id !== rItem.id) {
                    m += 1;
                    if (m === roleListObj[item].length) {
                      roleListObj[item].push(rItem);
                    }
                  }
                });
              }
            }
          });
        });
        this.roleListObj = roleListObj;
      }else if (e == 1) {
        this.current = row;
        this.deleVisible = true;
      }else if(e == 2){
        this.codeVisible = true;
        this.employeesInformation(row)
      }else if(e == 3){
        this.unbindEwechat(row)
      }

    },//解绑企业微信
    unbindEwechat(row){
      this.$confirm('确认解除该员工企业微信绑定？').then(_ => {
      unbindEwechat({store_user_id:[row.store_user_id]}).then((res) => {
        console.log(res);
        if(res.code==1){
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.loading2 = false;
          this.getList();
        }

      })
    })


    },
    //获取绑定url生成二维码
    employeesInformation(row){
      let that=this;
      const codeHtml = document.getElementById("qrcode");
      if(codeHtml!=null){
         codeHtml.innerHTML = "";
      }

      bindEwechatUrl({store_user_id:[row.store_user_id]}).then((res) => {
        let obg={
          buss_hid:res.data.buss_hid,
          auth_state:res.data.auth_state,
        }
        let data=JSON.stringify(obg);
        console.log(data);
        var qrcode = new QRCode(that.$refs.qrCodeUrl, {
                  text: data, // 需要转换为二维码的内容
                  // text: 'http://192.168.10.247:8081/member/login', // 需要转换为二维码的内容
                  width: 200,
                  height: 200,
                  colorDark: '#000000',
                  colorLight: '#ffffff' ,
                  correctLevel:  QRCode.CorrectLevel.H
        });
        store_user_id=row.store_user_id;
        timename=setInterval(function(){
          that.bindPersonEwechatUrl()
        },2000);

      })
     },//查询该员工是否扫码绑定企业微信，轮询差刷新数据
     bindPersonEwechatUrl(){
       bindPersonEwechatUrl({store_user_id:store_user_id}).then((res)=>{
         if(res.code==1){
           if(res.data.bind_type==3){
              clearInterval(timename);//绑定成功
              this.$message({
                message: '绑定成功',
                type: 'success'
              });
              const codeHtml = document.getElementById("qrcode");
              codeHtml.innerHTML = "";
              this.codeVisible=false;
              clearInterval(timename);
              this.getRoleList();
           }
         }else{
            clearInterval(timename);
         }
         console.log(res);
       })
     },

     /*
     *  导出
     */
    handleExport() {
      this.exportLoading = true;
      ListRequest({
        export: 2,
      })
        .then((res) => {
          this.exportLoading = false;
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `员工列表.csv`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    handleChoose(arr) {
      const merchantList = [...this.merchantList];
      const currentMerchantList = [];
      const roleList = [...this.roleList];
      const roleListObj = { ...this.roleListObj };
      arr.forEach((item) => {
        merchantList.forEach((mItem) => {
          if (mItem.hierarchy_id === item) {
            currentMerchantList.push(mItem);
          }
        });
      });
      this.currentMerchantList = currentMerchantList;
      // 处理不同门店有不同职位
      arr.forEach((item) => {
        roleList.forEach((rItem) => {
          if (rItem.hierarchy_id === item || rItem.hierarchy_id === '1') {
            if (!roleListObj[item]) {
              roleListObj[item] = [];
            }
            let m = 0;
            if (!roleListObj[item].length) {
              roleListObj[item].push(rItem);
            } else {
              roleListObj[item].forEach((objItem) => {
                if (objItem.id !== rItem.id) {
                  m += 1;
                  if (m === roleListObj[item].length) {
                    roleListObj[item].push(rItem);
                  }
                }
              });
            }
          }
        });
      });
      this.roleListObj = roleListObj;
      console.log(this.roleListObj);
    },
    handleRow ({ row, rowIndex }) {
      row.index = rowIndex + 1;
    },
    filterMerchant (value, row) {
      return row.hierarchy_name.includes(value);
    },
    filterRoles (value, row) {
      return row.role_name.includes(value);
    },
    filterGender (value, row) {
      return row.gender == value;
    },
    filterStatus (value, row) {
      return row.status == value;
    },
    getRoleList() {
      roleListReq()
        .then((res) => {
          if (res.code === 1) {
            const filterRolesArr = [];
            res.data.forEach((item) => {
              const obj = {
                text: item.title,
                value: item.title,
              };
              filterRolesArr.push(obj);
            });
            this.filterRolesArr = filterRolesArr;
            this.roleList = res.data;
          }
        });
    },
    getHierList() {
      hierListReq()
        .then((res) => {
          if (res.code === 1) {
            this.agentList = res.data;
          }
        })
    },
    handleDele() {
      userDelete(this.current.admin_user_id)
        .then((res) => {
          if (res.code === 1) {
            if (this.listData.list.length === 1 && this.formInline.pageint > 1) {
              this.formInline.pageint -= 1;
            }
            this.deleVisible = false;
            this.getList();
          }
        })
    },//关闭二维码
    handleCloseCode(){
      const codeHtml = document.getElementById("qrcode");
      codeHtml.innerHTML = "";
      this.codeVisible=false;
      clearInterval(timename);
    },//关闭二维码
    handleCode(){
      const codeHtml = document.getElementById("qrcode");
      codeHtml.innerHTML = "";
      this.codeVisible=false;
      clearInterval(timename);
    },
    // 新增or编辑
    handleTrue() {
      if (moment(this.form.hiredate) > moment()) {
        this.$message.error('入职时间不能大于当前时间');
        return;
      }
      if (this.form.id) {
        const hierarchy_group = [];
        this.form.hierarchy_ids.forEach((item) => {
          const obj = {
            group_id: this.role[item],
            hierarchy_id: item,
          };
          hierarchy_group.push(obj);
        });
        if (hierarchy_group.length) {
          for (let i = 0; i < hierarchy_group.length; i += 1) {
            const item = hierarchy_group[i];
            if (!item.group_id) {
              this.$message.error('请选择职位');
              return;
            }
          }
        }
        this.loading2 = true;
        const reqObj = { ...this.form, hierarchy_group };
        edit(reqObj)
          .then((res) => {
            if (res.code === 1) {
              this.handleClose();
              this.loading2 = false;
              this.getList();
            }
          })
          .catch(() => {
            this.loading2 = false;
          });
      } else {
        const hierarchy_group = [];
        this.form.hierarchy_ids.forEach((item) => {
          const obj = {
            group_id: this.role[item],
            hierarchy_id: item,
          };
          hierarchy_group.push(obj);
        });
        if (hierarchy_group.length) {
          for (let i = 0; i < hierarchy_group.length; i += 1) {
            const item = hierarchy_group[i];
            if (!item.group_id) {
              this.$message.error('请选择职位');
              return;
            }
          }
        }
        const reqObj = { ...this.form, hierarchy_group };
        this.loading2 = true;
        create(reqObj)
          .then((res) => {
            if (res.code === 1) {
              this.handleClose();
              this.loading2 = false;
              this.getList();
            }
          })
          .catch(() => {
            this.loading2 = false;
          });
      }
    },
    handleClose() {
      this.current.select = null;
      this.dialogVisible = false;
      this.deleVisible = false;
      this.form = {
        status: '10',
      };
      this.role = {};
      this.edit = false;
      this.roleListObj = {};
      this.currentMerchantList = [];
    },

    changePage(page) {
      const formInline = { ...this.formInline };
      formInline.page = page;
      this.formInline = formInline;
      this.getList();
    },
    getList() {
      this.loading = true;
      ListRequest(this.formInline)
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list.forEach((item) => {
              item.role_name = item.role_name.split(' ');
              item.hierarchy_name = item.hierarchy_name.split(' ');
              item.update_time = moment(item.update_time * 1000).format('YYYY-MM-DD HH:mm:ss');
            });
            this.listData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.formInline.pageint = 1;
      this.getList();
    },
    handleClick() {
      this.dialogVisible = true;
    },
    getMerchantList() {
      getMerchantListReq()
        .then((res) => {
          if (res.code === 1) {
            const filterMerchantList = [];
            res.data.forEach((item) => {
              const obj = {
                text: item.hierarchy_name,
                value: item.hierarchy_name,
              };
              filterMerchantList.push(obj);
            });
            this.filterMerchantList = filterMerchantList;
            this.merchantList = res.data;
          }
        });
    },
    //获取系统权限
    getMyAuth(){
      getMyAuth({}).then((res) => {
        this.AuthpermissionsData=res.data;
      })
    },
  },
  created() {
    // this.getHierList();
    this.getList();
    this.getMerchantList();
    this.getMyAuth();
  },
  // beforeRouteLeave(to, from, next) {
  //   this.$node.parent.componentInstance.cach = {};
  //   this.$node.parent.componentInstance.keys = [];
  //   next();
  // },
};
</script>

<style scoped lang="less">
.qrcode{
    display: flex;
    align-content: center;
    justify-content: center;
    img {
        width: 132px !important;
        height: 132px;
        background-color: #fff; //设置白色背景色
        padding: 6px; // 利用padding的特性，挤出白边
        box-sizing: border-box;
    }
}
.Code_text{
  text-align: center;
  margin-top: 40px;
  font-size: 16px;
  color: red;
}
.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
.flexBox {
  display: flex;
}
.card {
  padding-bottom: 0;
  transition: all 0.3s;
  overflow: hidden;
}
/deep/ .el-button--text {
  &:nth-child(2) {
    color: #FD563A;
  }
}
.btns {
  margin-bottom: 20px;
  padding-right: 30px;
  position: relative;
  &::after {
    content: '';
    width: 1px;
    height: 38px;
    background: #E9E9E9;
    position: absolute;
    right: -18px;
    top: 3px;
  }
}
.form {
  padding-left: 30px;
}
</style>
